<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" md="5">
        <v-toolbar class="mb-2" v-if="estadoEdit.idTipo">
          <v-btn icon @click.stop="estadoEdit = {}">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          Editando
          <status-chip class="ml-2" :value="estadoEdit.tipo" />
        </v-toolbar>
        <EditarTipoContacto :tipo="estadoEdit" :key="estadoEdit.idTipo" @reload="getEstados(); estadoEdit = Object.create(Object.prototype)" />
      </v-col>
      <v-col cols="12" md="7">
        <v-subheader>Tipos contacto</v-subheader>
        <v-card class="d-flex flex-wrap pa-2 align-start" style="gap: 15px">
          <v-alert
            max-width="500"
            style="width: 100%"
            class="ma-0"
            text
            prominent
            dense
            :key="tipo.idTipo"
            v-for="tipo in tipos"
            :color="getSituacionIcon(tipo.tipo).color"
            :icon="getSituacionIcon(tipo.tipo).icon"
          >
            <div class="d-flex">
              <div v-text="tipo.tipo" class="text-h6" />
              <v-spacer></v-spacer>
              <v-btn
                text
                small
                rounded
                :color="getSituacionIcon(tipo.tipo).color"
                @click.stop="estadoEdit = tipo"
              >
                <v-icon left>mdi-pencil</v-icon>
                <!-- <v-icon v-else left>mdi-lock</v-icon> -->
                Editar
              </v-btn>
            </div>
            <v-sheet
              width="100%"
              height="2px"
              class="mb-2"
              :color="getSituacionIcon(tipo.tipo).color"
            ></v-sheet>
            <div>{{ tipo.descripcion }}</div>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getSituacionIcon } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    EditarTipoContacto: () => import("@/components/controles/EditarTipoContacto.vue"),
    Editable: () => import("@/components/Editable.vue"),
  },
  data() {
    return {
      nuevoEstado: {
        icon: null,
        color: null,
        text: null,
      },
      estadoEdit: {},
      tipos: []
    };
  },
  methods: {
    getSituacionIcon,
    async getEstados() {
      const { data } = await axios(`${process.env.VUE_APP_API_URL}/opciones/tiposContactos.php`)
      this.tipos = data;
    },
  },
  mounted() {
    this.getEstados();
  },
};
</script>

<style></style>
